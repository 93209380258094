import * as React from "react"
import styled from "styled-components"
import { rouge, skyBlue, white } from "../../../constants/colors"
import { gillFont } from "../../../constants/fonts"
import FirstNameField from "./fields/FirstNameField"
import LastNameField from "./fields/LastNameField"
import EmailField from "./fields/EmailField"
import CompanyField from "./fields/CompanyField"
import WebsiteField from "./fields/WebsiteField"
import ReferralNameField from "./fields/ReferralNameField"
import TheirEmailField from "./fields/TheirEmailField"
import MessageField from "./fields/MessageField"
import SourceField from "./fields/SourceField"
import BorderedButton from "../../button/BorderedButton"
import ReferUsFormType from "../../../model/ReferUsFormType"
import IconTrackImpact from "../../../../svg/icon-attention.inline.svg"
import { screenSizes } from "@social-supermarket/social-supermarket-components"

export const DEFAULT_REFER_TO_US_FORM_STATE: ReferUsFormType = {
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  website: "",
  referralName: "",
  theirEmail: "",
  message: "",
  source: "",
}

const StyledForm = styled.form`
  width: 100%;
  max-width: 525px;
  margin-bottom: 0;
  justify-self: center;
  @media (max-width: ${screenSizes.smallMobile}px) {
    max-width: 320px;
  }
`

const NameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${screenSizes.smallMobile}px) {
    flex-direction: column;
  }
`

const Input = styled.div`
  width: 100%;
  max-width: 250px;
  @media (max-width: ${screenSizes.smallMobile}px) {
    max-width: unset;
  }
`

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`

const ErrorMessage = styled.span`
  color: ${rouge};
  font-size: 18px;
  line-height: 32px;
  font-family: ${gillFont};
  margin-left: 15px;
`

const fields = [
  FirstNameField,
  LastNameField,
  EmailField,
  CompanyField,
  WebsiteField,
  ReferralNameField,
  TheirEmailField,
  SourceField,
]

interface ReferUsFormProps {
  onChange: (name: string, value: number | string | boolean) => void
  onBlur?: (name: string) => void
  state: ReferUsFormType
  isValid: boolean
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void
  validate: boolean
}

const ReferUsForm = ({
  isValid,
  onChange,
  onBlur,
  state,
  validate,
  onSubmit,
}: ReferUsFormProps) => {
  return (
    <StyledForm>
      <NameContainer>
        <Input>
          <FirstNameField onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
        </Input>
        <Input>
          <LastNameField onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
        </Input>
      </NameContainer>
      <EmailField onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
      <CompanyField onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
      <WebsiteField onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
      <ReferralNameField onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
      <TheirEmailField onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
      <MessageField onChange={onChange} state={state} />
      <SourceField validate={validate} state={state} onChange={onChange} />
      <BorderedButton
        onClick={onSubmit}
        width="100%"
        color={skyBlue}
        secondaryColor={white}
        margin="30px 0 0 0"
      >
        Send Form
      </BorderedButton>
      {!isValid && (
        <ErrorContainer>
          <IconTrackImpact />
          <ErrorMessage>Something is wrong. Please try again.</ErrorMessage>
        </ErrorContainer>
      )}
    </StyledForm>
  )
}

ReferUsForm.isValid = (state: ReferUsFormType) => {
  return fields.every(Field => Field.isValid(state))
}

export default ReferUsForm
