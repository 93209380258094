import * as React from "react"
import styled from "styled-components"
import { screenSizes } from "@social-supermarket/social-supermarket-components"

const Container = styled.div`
  padding: 100px 40px;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: ${screenSizes.bigDesktop}px) {
    padding: 80px 40px;
  }
  @media (max-width: ${screenSizes.tablet}px) {
    padding: 80px 28px;
  }
  @media (max-width: ${screenSizes.smallMobile}px) {
    padding: 80px 20px;
  }
`

interface BlockProps {
  className?: string
  children?: React.ReactNode
}

const Block: React.FC<BlockProps> = ({ className = "", children }): JSX.Element => (
  <Container className={className}>{children}</Container>
)

export default Block
