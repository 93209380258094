import React from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import ImageBoundless from "../../../images/image-boundless.jpg"
import ImageChangePlease from "../../../images/image-change-please.jpg"
import ImageDivineFamilyWalking from "../../../images/image-divine-family-walking.jpg"
import IconCharitableContributions from "../../../svg/icon-charitable-contributions-black.inline.svg"
import IconEcoConscious from "../../../svg/icon-eco-conscious-black.inline.svg"
import IconEconomicEmpowerment from "../../../svg/icon-economic-empowerment-black.inline.svg"
import IconVegan from "../../../svg/icon-vegan-black.inline.svg"
import FemaleLedBusiness from "../../../svg/female-led-business-black.inline.svg"
import Description from "../landing-page/Description"
import Title from "../landing-page/Title"
import { screenSizes } from "@social-supermarket/social-supermarket-components"

const CarouselWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 100px;
  @media (max-width: ${screenSizes.desktop}px) {
    padding-bottom: 80px;
  }
  @media (max-width: ${screenSizes.tablet}px) {
    padding-bottom: 60px;
  }
`

const CarouselItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${screenSizes.desktop}px) {
    flex-wrap: wrap;
  }
  @media (max-width: ${screenSizes.tablet}px) {
    padding: 0 30px;
  }
`

const CarouselItemImageWrapper = styled.div`
  padding: 0 30px 60px 60px;
  max-width: 650px;
  @media (max-width: ${screenSizes.desktop}px) {
    max-width: 100%;
    padding: 0 60px 20px 60px;
  }
  @media (max-width: ${screenSizes.tablet}px) {
    padding: 0;
  }
`

const CarouselItemInfoWrapper = styled.div`
  padding: 15px 60px 60px 30px;
  max-width: 350px;
  @media (max-width: ${screenSizes.desktop}px) {
    padding: 0px 60px 60px 60px;
    max-width: 100%;
  }
  @media (max-width: ${screenSizes.tablet}px) {
    padding: 40px 0 0 0;
  }
`

const CarouselItemLogos = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 25px;
  }
  @media (max-width: ${screenSizes.tablet}px) {
    padding-bottom: 20px;
  }
`

const carouselItems = [
  {
    image: ImageDivineFamilyWalking,
    title: "Divine Chocolate",
    description:
      "When a group of Ghanaian cocoa farmers decided to create their own chocolate company, Divine was the result. Founded in 1998, this seriously tasty chocolate was ahead of its time as the first farmer-owned Fairtrade chocolate product.",
    logos: [IconCharitableContributions, IconEcoConscious, IconEconomicEmpowerment],
  },
  {
    image: ImageChangePlease,
    title: "Change Please",
    description:
      "Inspired by the nation’s love of coffee and rising rates of rough-sleeping, Change Please provides people experiencing homelessness with barista training, jobs paying the London living wage as well as help with housing, bank accounts and mental wellbeing.",
    logos: [IconEcoConscious, IconCharitableContributions, IconVegan],
  },
  {
    image: ImageBoundless,
    title: "Boundless Activated Snacking",
    description:
      "Every pack of Boundless Activated Snacking’s more-ish nut and seed mixes fund life-changing water projects with charity, FRANK – sorting out safe water and sanitation. They’re also as good for you and your gut as they are tasty. Hurrah!",
    logos: [IconEcoConscious, IconCharitableContributions, FemaleLedBusiness, IconVegan],
  },
]

const BREAK_POINTS = {
  320: {
    slidesPerView: 1,
  },
  768: {
    slidesPerView: 1,
  },
  956: {
    slidesPerView: 1,
  },
}

const InfoCarousel = () => (
  <CarouselWrapper>
    <Swiper pagination={{ clickable: true }} navigation breakpoints={BREAK_POINTS} loop>
      {carouselItems.map(({ title, image, description, logos }) => (
        <SwiperSlide key={title}>
          <CarouselItemContainer>
            <CarouselItemImageWrapper>
              <img src={image} alt={image} />
            </CarouselItemImageWrapper>
            <CarouselItemInfoWrapper>
              <Title margin="30px 0 0 0">{title}</Title>
              <Description margin="30px 0 50px 0">{description}</Description>
              <CarouselItemLogos>
                {logos.map(Logo => (
                  <Logo key={Logo} />
                ))}
              </CarouselItemLogos>
            </CarouselItemInfoWrapper>
          </CarouselItemContainer>
        </SwiperSlide>
      ))}
    </Swiper>
  </CarouselWrapper>
)

export default InfoCarousel
